// Copyright 2024. WebPros International GmbH. All rights reserved.

import Header from '@platform360/auth/web/components/Header';
import { Form, FormErrors } from '@plesk/ui-library';
import { useTranslate } from '@platform360/libs/shared-web/locale/useTranslate';
import { SetPasswordUseCase } from '@platform360/auth/web/types';
import FormFieldPassword from '@platform360/libs/shared-web/components/FormFieldPassword';

type ChangePasswordFormProps = {
    onSubmit: (password: string) => void;
    errors?: FormErrors;
    isLoading: boolean;
    isSuccess: boolean;
    useCase: SetPasswordUseCase;
};

type FormValues = {
    password: string;
};

const DataTestIds = {
    FORM: 'ChangePassword--form',
};

export const ChangePasswordForm = ({
    onSubmit,
    isLoading,
    isSuccess,
    errors,
    useCase,
}: ChangePasswordFormProps) => {
    const translate = useTranslate('auth.ChangePasswordForm');

    const handleSubmit = (values: FormValues) => onSubmit(values.password);
    // eslint-disable-next-line @typescript-eslint/consistent-return
    const renderTitle = () => {
        switch (useCase) {
            case 'reset':
                return translate('resetTitle');
            case 'set':
                return translate('setTitle');
        }
    };

    return (
        <Form
            onSubmit={handleSubmit}
            state={isLoading ? 'submit' : undefined}
            errors={errors}
            applyButton={false}
            cancelButton={false}
            submitButton={{
                children: translate('submitButton'),
                type: 'submit',
                disabled: isSuccess,
            }}
            hideRequiredLegend
            vertical
            data-type={DataTestIds.FORM}
        >
            <Header>{renderTitle()}</Header>
            <FormFieldPassword name="password" label={translate('passwordLabel')} />
        </Form>
    );
};

export default ChangePasswordForm;
