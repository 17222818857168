// Copyright 2024. WebPros International GmbH. All rights reserved.
import {
    FormFieldPassword as FormFieldPasswordBase,
    FormFieldPasswordProps,
} from '@plesk/ui-library';
import { SetOptional } from 'type-fest';

const FormFieldPassword = (props: SetOptional<FormFieldPasswordProps, 'size'>) => (
    <FormFieldPasswordBase
        {...props}
        // @ts-expect-error UILIB-1532
        passwordMeterProps={{
            onEstimate: (value, estimatePassword, defaultRules) => {
                const rules = defaultRules.map((rule) => {
                    if (rule.name === 'passwordTooShort') {
                        return {
                            ...rule,
                            score(passwd: string) {
                                return passwd.length < 12 ? -1 : 0;
                            },
                        };
                    }

                    return rule;
                });

                const { strength, suggestions } = estimatePassword(value, rules);

                return value.length < 12
                    ? {
                          strength: 'VeryWeak',
                          suggestions,
                      }
                    : { strength, suggestions };
            },
        }}
    />
);

export default FormFieldPassword;
